<template>
  <div>
    <b-col class="col-12 align-content-center py-2 py-md-3 py-xl-4">
      <div class="mx-auto col-10 col-sm-7 col-md-4 col-lg-3 col-xl-2 py-2">
        <b-img
          :src="require('@/assets/concura-logo-claim.png')"
          :style="
            $root.isCollapsed
              ? { 'max-height': '20vh' }
              : { 'max-height': '30vh' }
          "
          alt="logo concura"
          fluid-grow
        ></b-img>
      </div>
    </b-col>
    <div class="col-12 col-md-10 col-lg-7 col-xl-4 mx-auto mt-4">
      <h1>Recupera la tua password</h1>
      <br />
      <template v-if="passwordRecoveryType === 1">
        <template v-if="status.recoverSent === true">
          <h6>Mail inviata! Controlla la tua posta elettronica.</h6>
          <p class="mt-4">
            <!-- <span
              class="fw-bolder point text-secondary"
              @click="passwordRecoveryType = 2"
              >Non ho ricevuto l'email</span
            > -->
            <b-button
              type="button"
              variant="outline-secondary"
              @click="passwordRecoveryType = 2"
            >
              Non ho ricevuto l'email
            </b-button>
          </p>
        </template>
        <template v-else>
          <h6>
            Inserisci l'indirizzo email utilizzato in fase di registrazione.
          </h6>
          <b-form @submit.prevent="OnEmailSubmit">
            <b-form-input
              class="mx-auto my-3"
              name="email"
              type="email"
              v-model="recoverEmail"
              placeholder="email@ ..."
              :state="recoverEmail == null ? null : validEmail"
              required
            ></b-form-input>

            <b-row class="mt-4 justify-content-center">
              <b-button
                @click="$router.go(-1)"
                type="button"
                variant="outline-secondary mx-2"
                >Annulla</b-button
              >
              <b-button
                :disabled="recoverEmail == null ? false : !validEmail"
                type="submit"
                variant="secondary mx-2"
                >Invia</b-button
              >
            </b-row>
            <p class="mt-4">
              <b-button
                type="button"
                variant="outline-secondary"
                @click="passwordRecoveryType = 2"
              >
                Non ricordo l'indirizzo email
              </b-button>
            </p>
          </b-form>
        </template>
      </template>
      <template v-else-if="passwordRecoveryType === 2">
        <template v-if="status.recoverSent === true">
          <h6>
            Abbiamo inviato un sms contenente il link per ripristinare la
            password al numero di telefono indicato per questo codice fiscale.
            <br />
            <br />
            Attendi qualche istante.
          </h6>
          <p class="mt-4">
            <b-button
              type="button"
              variant="outline-secondary"
              @click="passwordRecoveryType = 3"
            >
              Non ho ricevuto l'sms
            </b-button>
            <!-- <span class="fw-bolder point" @click="passwordRecoveryType = 3"
              >Non ho ricevuto l'sms</span
            > -->
          </p>
        </template>
        <template v-else>
          <h6>Inserisci il tuo codice fiscale.</h6>
          <b-form @submit.prevent="OnCFSubmit">
            <b-form-input
              class="mx-auto mt-3 mb-4"
              name="cf"
              type="text"
              v-model="recoverCF"
              placeholder="CODICE FISCALE..."
              maxlength="16"
              minlength="16"
              :state="recoverCF == null ? null : validCf"
              required
            ></b-form-input>
            <b-row class="mt-4 justify-content-center">
              <b-button
                @click="passwordRecoveryType = 1"
                type="button"
                variant="outline-secondary mx-2"
                >Annulla</b-button
              >
              <b-button
                :disabled="recoverCF == null ? false : !validCf"
                type="submit"
                variant="secondary mx-2"
                >Invia</b-button
              >
            </b-row>
          </b-form>
        </template>
      </template>
      <template v-else>
        <template v-if="status.recoverSent === true">
          <h6>
            Un sms contenente il link per ripristinare la password al numero di
            telefono di riserva indicato su questo codice fiscale, se impostato.
            <br />
            Attendi qualche istante.
          </h6>
          <p class="mt-4">
            Se non lo hai impostato o non hai ricevuto nessun link e non sei
            quindi riuscito a recuperare l'accesso,
            <a
              class="fw-bolder point text-secondary"
              href="mailto:assistenza@concura.it"
            >
              invia una richiesta di supporto in assistenza
            </a>
            .
          </p>
          <b-row class="text-center">
            <b-button
              @click="$router.push({ name: 'login' })"
              variant="secondary  mx-auto"
              >Torna alla pagina di login</b-button
            >
          </b-row>
        </template>
        <template v-else>
          <h6>Inserisci il tuo codice fiscale.</h6>
          <b-form @submit.prevent="OnCFSubmit">
            <b-form-input
              class="mx-auto mt-3 mb-4"
              name="cf"
              type="text"
              v-model="recoverCF"
              placeholder="CODICE FISCALE..."
              maxlength="16"
              minlength="16"
              :state="recoverCF == null ? null : validCf"
              required
            ></b-form-input>
            <b-row class="mt-4 justify-content-center">
              <b-button
                @click="$router.go(-1)"
                type="button"
                variant="outline-secondary mx-2"
                >Annulla</b-button
              >
              <b-button
                :disabled="recoverCF == null ? false : !validCf"
                type="submit"
                variant="secondary mx-2"
                >Invia al numero di riserva</b-button
              >
            </b-row>
          </b-form>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "PasswordRecovery",
  data() {
    return {
      form: {
        email: this.$route.query.email,
        password: null,
        password_confirmation: null,
        token: this.$route.query.token,
      },
      has_minimum_lenth: true,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      recoveryTypes: {
        1: "mail",
        2: "firstPhone",
        3: "secondPhone",
      },
      passwordRecoveryType: 1,
      recoverCF: null,
      recoverEmail: null,
      showSecondNumberOption: false,
    };
  },
  computed: {
    validatePassword() {
      if (this.form.password) {
        this.has_minimum_lenth = this.form.password.length > 8;
        this.has_number = /\d/.test(this.form.password);
        this.has_lowercase = /[a-z]/.test(this.form.password);
        this.has_uppercase = /[A-Z]/.test(this.form.password);
        this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.form.password);
        return (
          this.has_minimum_lenth &&
          this.has_number &&
          this.has_lowercase &&
          this.has_uppercase &&
          this.has_special
        );
      }
      return false;
    },
    validateConfirm() {
      if (this.form.password) {
        return this.form.password_confirmation === this.form.password;
      }
      return false;
    },
    validEmail() {
      const validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return this.recoverEmail.match(validRegex) ? true : false;
    },
    validCf() {
      const validRegex =
        /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;
      if (this.recoverCF === 16) {
        return this.recoverCF.match(validRegex) ? true : false;
      }
      return this.recoverCF.match(validRegex) ? true : false;
    },
    ...mapState("utente", ["status", "user"]),
  },
  watch: {
    passwordRecoveryType: function (val) {
      this.resetRecoverAction();
    },
  },
  methods: {
    ...mapActions("utente", [
      "resetPassword",
      "recoverPassword",
      "recoverPasswordViaCf",
      "resetRecoverAction",
    ]),
    OnEmailSubmit() {
      const self = this;
      this.recoverPassword({ email: this.recoverEmail })
        .then((res) => {
          self.recoverEmail = null;
        })
        .catch((error) => {});
    },
    OnCFSubmit() {
      const self = this;
      var form = { cf: this.recoverCF };
      if (this.passwordRecoveryType > 2) {
        form = { cf: this.recoverCF, second: true };
      }
      this.recoverPasswordViaCf(form)
        .then((res) => {
          // self.recoverCF = null;
          self.showSecondNumberOption = true;
        })
        .catch((error) => {});
    },
  },
};
</script>
<style scoped>

input::placeholder {
  color: #f75010;
}
</style>
