<template>
  <div
    style="position: absolute; top: -120px; right: 60px"
  >
    <p class="need-help-text mb-1 text-secondary">Hai bisogno di aiuto?</p>
    <p class="need-help-text mb-1 text-secondary">Contattaci</p>
    <p class="d-flex">
      <a
        href="tel:800770272"
        class="d-flex justify-content-center align-items-center point"
      >
        <b-iconstack :font-scale="4.5" class="point">
          <b-icon stacked icon="circle-fill" variant="primary"></b-icon>
          <b-icon
            stacked
            icon="telephone-fill"
            variant="white"
            scale="0.6"
          ></b-icon>
        </b-iconstack>
      </a>
      <a
        :href="'mailto:' + mailAddress"
        class="ml-4 d-flex justify-content-center align-items-center point"
      >
        <b-iconstack :font-scale="4.5" class="point">
          <b-icon stacked icon="circle-fill" variant="primary"></b-icon>
          <b-icon
            stacked
            icon="envelope-fill"
            variant="white"
            scale="0.6"
          ></b-icon>
        </b-iconstack>
      </a>
    </p>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions } from "vuex"
import ChangeLogComponent from "@/components/layout/ChangeLogComponent.vue"
import VueBarcode from "vue-barcode"

export default {
  components: {
    ChangeLogComponent,
    VueBarcode
  },
  data() {
    return {
      mailAddress: process.env.VUE_APP_SUPPORT_MAIL_ADDRESS
    }
  },
  created() {},

  mounted() {}
}
</script>

<style scoped>
.need-help-text {
  font-size: 0.7rem;
}
</style>
